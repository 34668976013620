<template>
  <div>
    <Package-add-new
      :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
      @clicked="one"
      :package-options="packageOptions"
      :payment-methods="paymentMethods"
      :user-data="userData"
    />
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchString"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button
            variant="primary"
            @click="isAddNewPackageSidebarActive = true"
            v-if="$can('read', 'senior_pt') || $can('read','staff') || $can('read','pt')"
          >
            <span class="text-nowrap">Add Package</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      striped
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(average)="data">
        ${{ (data.item.amount / data.item.start_quantity).toFixed(0) }}
      </template>
      <template #cell(amount)="data">
       ${{(data.item.amount)}}
      </template>
    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }} 
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="mt-1"
          @change="handleChangePage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import PackageAddNew from './SidebarNewPackage.vue'
import router from '@/router'
import {
  BTable, BAvatar, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    router,
    PackageAddNew,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      centerOptions: [],
      paymentMethods: [],
      packageOptions: [],
      isAddNewPackageSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'member_package_id', label: 'ID' }, 
        { key: 'package_name', label: 'Name' },
        { key: 'quantity', label: 'quantity' },
        { key: 'balance', label: 'Balance' },
        { key: 'average', label: 'average' },
        { key: 'Amount', label: 'Amount' },
        { key: 'create_date', label: 'Create Date' },
        { key: 'expire_at', label: 'expire date' },
        { key: 'status', label: 'status' }
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Active', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  props: {
    userData : {
      type: Object
    }
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    console.log(this.userData)
    this.get_data()
    this.get_center_data()
    this.get_package_data()
    this.get_payment_method()
  },
  methods: {
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    one() {
      this.isAddNewPackageSidebarActive = false
      this.get_data()
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/member/${router.currentRoute.params.id}/packages/?type=package&perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
        .then(res => {
          this.items = res.data.packages
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          //  console.log( res.data.members.length)
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    get_package_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/packages/')
        .then(res => {
          this.packageOptions = res.data.packages
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items
      console.log(items)
      if (items[0].status == "PENDING") {
        this.$router.push(`/epad/${items[0].member_package_id}/`)
      } else {
        this.$router.push(`/apps/cloudfit/package/${items[0].member_package_id}/`)
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
